.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.button {
    border-radius: var(--border-radius);
    padding: var(--xs-spacing);
    margin: var(--xxs-spacing) var(--s-spacing) var(--xs-spacing) 0;
    cursor: pointer;
}