span.show-more-less-clickable {
    cursor:pointer;
    text-decoration:underline;
}

span.show-more-less-clickable:hover{
    text-decoration:none;
}
.mobile .common_catalogs__T5bZ2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--black15);
        padding-bottom: var(--l-spacing);
        margin-bottom: var(--l-spacing);
    }

    .mobile .common_catalogMore__kQlAF {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: var(--primary);
        width: 100%;
        margin-top: var(--m-spacing);
    }

    .mobile .common_catalogMore__kQlAF span {
            margin-right: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--d-font-size);
        }

    .mobile .common_allowLeft__qmS_J {
        margin-left: auto !important;
    }

    .mobile .common_catalog__JnE0z {
        width: 50%;

        margin: var(--m-spacing) 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .mobile .common_catalog__JnE0z .common_catalogImg__3wcA3 {
            height: 156px;
            width: 156px;
            border-radius: 0;
            color: var(--black);
            font-size: var(--h5-font-size);
            font-weight: var(--semi-bold);
            -o-object-fit: contain;
               object-fit: contain;
        }

    .mobile .common_catalog__JnE0z .common_noCatalogImg__zu_MG {
            width: 156px;
            height: 156px;
        }

    .mobile .common_catalog__JnE0z .common_catalogLabel__0k4qz {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--m-font-size);
            color: var(--black60);
        }

.common_catalogInner__iWNdY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black05);

    width: 220px;
    height: 220px
}

@media screen and (max-width: 419px) {

.common_catalogInner__iWNdY {
        width: 156px;
        height: 156px
}
    }


.desktop .common_catalogs__T5bZ2 {
        border-top: 1px solid var(--black15);
        border-bottom: 1px solid var(--black15);
        padding: 40px 20px;
        display: flex;
        flex-direction: row;
    }


.desktop .common_catalogs__T5bZ2 .common_slideButton__ylZnW:first-child {
            margin-right: var(--l-spacing);
        }


.desktop .common_catalogs__T5bZ2 .common_slideButton__ylZnW:last-child {
            margin-left: var(--l-spacing);
        }


.desktop .common_catalog__JnE0z {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 220px;
    }


.desktop .common_catalog__JnE0z .common_catalogImg__3wcA3{
            width: 220px;
            max-height: 220px;
            height: auto;
            color: var(--black);
            font-size: var(--h4-font-size);
            font-weight: var(--semi-bold);
            border-radius: 0;
            -o-object-fit: contain;
               object-fit: contain;
        }


.desktop .common_catalog__JnE0z .common_noCatalogImg__zu_MG {
            width: 220px;
            height: 220px;
        }


.desktop .common_catalog__JnE0z .common_catalogLabel__0k4qz {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--d-font-size);
            color: var(--black80);
        }


.desktop .common_catalogMore__kQlAF {
        background: #F4F4F4 !important;
        height: 220px;
        width: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


@media screen and (max-width: 419px) {


.common_templates__gH_aG {
        margin-bottom: var(--l-spacing)
}

    }


@media screen and (min-width: 420px) {

    .common_templates__gH_aG .common_slideButton__ylZnW {
            position: relative;
            z-index: 10
    }

            .common_templates__gH_aG .common_slideButton__ylZnW:last-child {
                top: -190px;
                left: calc(100% - 40px);
            }

            .common_templates__gH_aG .common_slideButton__ylZnW:first-child {
                top: 180px;
                left: 5px;
            }
        }

.common_template__hQ87R {
    width: 100%;
}

.common_template__hQ87R .common_templateImage__s1_NS {
        height: 250px;
        margin: auto;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        padding: 0 var(--xxl-spacing);
        justify-content: center;
        align-items: center;
        background-size: contain;
        background-position: center center
    }

@media screen and (min-width: 420px) {

    .common_template__hQ87R .common_templateImage__s1_NS {
            height: 340px;
            width: 600px;
            padding: 0 var(--xxxl-spacing)
    }

        }

.common_template__hQ87R .common_tplTitle__5QFPW {
        font-weight: var(--semi-bold);
        font-size: var(--h4-font-size);
        color: var(--secondary);
        padding-bottom: var(--s-spacing);
        text-align: center;
    }

.common_template__hQ87R .common_tplDesc__H17HQ {
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--secondary);
        text-align: center;
    }

.common_slideButtonMobile__gZt0G .common_slideButton__ylZnW {
        margin: 0 var(--l-spacing)
    }

@media screen and (max-width: 419px) {
    .common_slideButtonMobile__gZt0G .common_slideButton__ylZnW {
            position: absolute;
            z-index: 10
    }

            .common_slideButtonMobile__gZt0G .common_slideButton__ylZnW:last-child {
                left: 100px;
            }

            .common_slideButtonMobile__gZt0G .common_slideButton__ylZnW:first-child {
                right: 180px;
            }
        }

.common_slideButtonMobile__gZt0G {
    position: relative;
    z-index: 10;
    left: 60%;
    bottom: 150px;
    display: inline-block;
}

.common_heading__qKJCs {
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
    margin: var(--xxl-spacing) var(--xl-spacing) var(--l-spacing);
}


.common_viewAll__31JsP {
    padding: var(--l-spacing) var(--m-spacing);
 }
@media screen and (min-width: 420px) {
    .common_viewAll__31JsP {
        padding: 40px 40px;
 }
    }
.common_viewAll__31JsP .common_head__gaHhc {
        margin: var(--l-spacing) 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
.common_viewAll__31JsP .common_name__uBZOs {
        font-size: var(--h4-font-size);
        font-weight: var(--semi-bold);
        color: var(--black);
    }
.common_viewAll__31JsP .common_viewAllTop__VRNRB {
        font-size: var(--h5-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
    }
.common_viewAll__31JsP .common_viewAllTop__VRNRB span {
            margin-right: var(--s-spacing);
        }
.common_viewAll__31JsP .common_footer__MMjiX {
        margin: var(--l-spacing) 0;
        font-weight: var(--semi-bold);
        text-align: center;
    }
.common_viewAll__31JsP .common_footer__MMjiX span {
            font-size: var(--d-font-size);
            color: var(--primary);
            margin-right: var(--s-spacing);
        }
.common_viewAll__31JsP {
 
 
    border-bottom: 1px solid var(--black15);
    padding-bottom: var(--m-spacing);
}
 

.ProductColors_outer__8iSRi {
    width: 20px;
    height: 20px;
    margin-right: 16px !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ProductColors_outline__pVIda {
    outline-offset: 2px;
    outline-style: solid !important;
}

.ProductColors_link__kywcM {
    font-size: var(--d-font-size);
    border-bottom: 1px solid;
    cursor: pointer;
}

.ProductTags_container__PQEmU {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ProductTags_button__yeRpH {
    border-radius: var(--border-radius);
    padding: var(--xs-spacing);
    margin: var(--xxs-spacing) var(--s-spacing) var(--xs-spacing) 0;
    cursor: pointer;
}
.GetPrice_decimal__248ah {
    font-size: var(--xxs-font-size) !important;
    font-weight: var(--medium-bold) !important;
}

.ProductCard_container__g5m11 {
    margin-bottom: var(--m-spacing);
 }

 .ProductCard_imgContainer__qv9t9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black05);
    position: relative;

    height: 155px;
    width: 155px
 }

 @media screen and (min-width: 420px) {

 .ProductCard_imgContainer__qv9t9 {
        height: 232px;
        width: 232px
        /*z-index: -99999 !important*/
 }
    }

 .ProductCard_oos__ER8Mf {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px
 }

 @media screen and (min-width: 420px) {

 .ProductCard_oos__ER8Mf {
        border-radius: 5px;
        padding: var(--s-spacing) var(--xl-spacing)
 }
    }

 .ProductCard_img__jjj7V {
    width: 155px;
    height: 155px;
    -o-object-fit: cover;
       object-fit: cover
}

 @media screen and (min-width: 420px) {

 .ProductCard_img__jjj7V {
        width: 232px;
        height: 232px
}
    }

.ProductCard_click__RJFuk {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.ProductCard_priceContainer__GMRZO {
    /*width: 100%;*/
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.ProductCard_bottomContainer__A4Vnn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ProductCard_large__RPdVE .ProductCard_bottomContainer__A4Vnn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center
    }

@media screen and (min-width: 420px) {
    .ProductCard_large__RPdVE .ProductCard_bottomContainer__A4Vnn {
            justify-content: space-between
    }
        }

.ProductCard_large__RPdVE .ProductCard_imgContainer__qv9t9 {
        height: 345px;
        width:  345px
    }

@media screen and (min-width: 420px) {

    .ProductCard_large__RPdVE .ProductCard_imgContainer__qv9t9 {
            height: 496px;
            width: 496px
    }
        }

.ProductCard_large__RPdVE .ProductCard_img__jjj7V {
        width: 345px;
        height: 345px;
        -o-object-fit: cover;
           object-fit: cover
    }

@media screen and (min-width: 420px) {

    .ProductCard_large__RPdVE .ProductCard_img__jjj7V {
            width: 496px;
            height: 496px
    }
        }

.ProductCard_name__qtBqz {
    margin-top: var(--m-spacing);
    font-weight: var(--semi-bold);
    font-size: var(--m-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.ProductCard_price__i17Cn,
.ProductCard_price2__YZEBB {
    margin-bottom: var(--xs-spacing);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 100%; */
}

.ProductCard_price2__YZEBB {
    margin-bottom: var(--s-spacing);
}

.ProductCard_amount__kkFFQ,
.ProductCard_discount__LoutH {
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--semi-bold);
    margin-right: var(--s-spacing);
}

.ProductCard_amount__kkFFQ span {
        font-size: var(--s-font-size);
    }

.ProductCard_discount__LoutH {
    font-size: var(--s-font-size);
}

.ProductCard_originalAmount__5G7Fe {
    font-size: var(--s-font-size);
    color: var(--black50);
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    margin-right: var(--s-spacing);
}

.ProductCard_discount__LoutH {
    color: var(--green);
}

.ProductCard_unit__5lKRO {
    color: var(--black50);
    text-transform: capitalize;
}



.Skeleton_container__Pu6xx {
    padding: var(--s-spacing) var(--m-spacing);
}

.Skeleton_main__2A792 {

}

.Skeleton_flashCard__QhXos {

}

.Skeleton_first__m_3FN {

}

.Skeleton_second__UakWt {

}

.DisablePopup_dialog__N9d4w{
    /* width: 586px !important; */
    height: 363px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important
}

@media screen and (max-width: 419px) {

.DisablePopup_dialog__N9d4w{
        font-size: var(--m-font-size);
        width: 100% !important;
        height: 11rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important
}
     }

.DisablePopup_image__dVuDn{
    text-align: center;
}

.DisablePopup_img__eoI9z{

    height:  76px;
    width:  76px
}

@media screen and (max-width: 419px) {

.DisablePopup_img__eoI9z{
        height:  42px !important;
        width:  42px !important
}
     }

.DisablePopup_content__JafNB{
    /* height: 87px; */
    margin-top: 28px

}

@media screen and (max-width: 419px) {

.DisablePopup_content__JafNB{
        margin-top: 18px

} 
    }

.DisablePopup_wrnText__2k7io{

    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding-left:20%;
    padding-right:20%;
    color: #212121
}

@media screen and (max-width: 419px) {

.DisablePopup_wrnText__2k7io{
        font-size: 12px !important;
        line-height: 16px !important
}
     }
.store_shopName__FyHc5 {
    font-size: var(--h5-font-size);
    font-weight: var(--semi-bold);
}

.mobile .store_shopIcon__Apmsz {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: var(--l-spacing) 0;
    }

.mobile .store_shopIcon__Apmsz img {
            height: 150px;
            width: 150px;
        }

.desktop .store_shopIcon__Apmsz {
        display: flex;
        flex-direction: row;
        padding: 30px 10%;
        background: #FAFAFA;
        justify-content: center;
        align-items: center;
    }

.desktop .store_shopIcon__Apmsz img.store_icon__sPF2_ {
            height: 162px;
            width: 290px;
            border-radius: 50px;
        }

.desktop .store_shopIcon__Apmsz .store_shopIconGrid__KV5ar {
            margin-left: var(--xl-spacing);
        }

