.shopName {
    font-size: var(--h5-font-size);
    font-weight: var(--semi-bold);
}

:global(.mobile) {
    .shopIcon {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: var(--l-spacing) 0;

        img {
            height: 150px;
            width: 150px;
        }
    }
}

:global(.desktop) {
    .shopIcon {
        display: flex;
        flex-direction: row;
        padding: 30px 10%;
        background: #FAFAFA;
        justify-content: center;
        align-items: center;

        img.icon {
            height: 162px;
            width: 290px;
            border-radius: 50px;
        }

        .shopIconGrid {
            margin-left: var(--xl-spacing);
        }
    }
}
