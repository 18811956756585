.viewAll {
    padding: var(--l-spacing) var(--m-spacing);
 
 
    @media (--desktop) {
        padding: 40px 40px;
    }
 
 
    .head {
        margin: var(--l-spacing) 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
 
 
    .name {
        font-size: var(--h4-font-size);
        font-weight: var(--semi-bold);
        color: var(--black);
    }
 
 
    .viewAllTop {
        font-size: var(--h5-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
 
 
        span {
            margin-right: var(--s-spacing);
        }
    }
 
 
    .footer {
        margin: var(--l-spacing) 0;
        font-weight: var(--semi-bold);
        text-align: center;
 
 
        span {
            font-size: var(--d-font-size);
            color: var(--primary);
            margin-right: var(--s-spacing);
        }
    }
 
 
    border-bottom: 1px solid var(--black15);
    padding-bottom: var(--m-spacing);
 }
 
