.dialog{
    /* width: 586px !important; */
    height: 363px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    @media (--phone) {
        font-size: var(--m-font-size);
        width: 100% !important;
        height: 11rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
     }
}

.image{
    text-align: center;
}

.img{

    height:  76px;
    width:  76px;

    @media (--phone) {
        height:  42px !important;
        width:  42px !important;
     }
}

.content{
    /* height: 87px; */
    margin-top: 28px; 

    @media (--phone) {
        margin-top: 18px; 
    }

}

.wrnText{

    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding-left:20%;
    padding-right:20%;
    color: #212121;
    @media (--phone) {
        font-size: 12px !important;
        line-height: 16px !important;
     }
}