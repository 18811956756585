.outer {
    width: 20px;
    height: 20px;
    margin-right: 16px !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.outline {
    outline-offset: 2px;
    outline-style: solid !important;
}

.link {
    font-size: var(--d-font-size);
    border-bottom: 1px solid;
    cursor: pointer;
}
