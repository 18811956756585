.container {
    padding: var(--s-spacing) var(--m-spacing);
}

.main {

}

.flashCard {

}

.first {

}

.second {

}
